.filter-wrapper {
  position: relative;
  z-index: 2000;
}

.table-container {
  height: calc(100vh - 210px);
  width: fit-content;
  overflow: auto;
  background-color: white;

  .MuiTableHead-root {
    .MuiTableCell-head {
      height: 68px;
    }
  }
  .MuiTableCell-root {
    max-width: 100%;
    white-space: nowrap;
  }
  #info-panel {
    .container {
      width: 35% !important;
    }
  }

  .empty-row {
    td.MuiTableCell-root {
      border-bottom: none;
    }
  }

  .table-row-cdi {
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
}
.MuiBackdrop-root.table-backdrop {
  z-index: 1000;
  height: 100%;
  margin-top: calc(-100vh - 210px);
  position: sticky;
  background: rgba(255, 255, 255, 0.8);
}

.hidden-column {
  display: none !important;
}
