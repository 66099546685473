.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .import-buttons {
    display: inline-flex;
    justify-content: space-around;
  }
}

.file-badge {
  .MuiBadge-badge {
    padding: 2px 6px 0px;
  }

  > span {
    background-color: var(--color-red-500);
  }
}
