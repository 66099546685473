.dashboard-dialog {
  .MuiPaper-root {
    padding: 2rem;
    border-radius: 16px;
  }
  .dropzone {
    position: relative;
    border: 1px dashed rgba(5, 13, 18, 0.39);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 24px;
    text-align: center;
    margin-bottom: 2rem;
  }
  .MuiBackdrop-root.file-backdrop {
    z-index: 1000;
    position: absolute;
    height: 100%;
    opacity: 0.1;
    background: #ffffffe8;
  }

  .file-container > div {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    margin-top: 1rem;
  }

  .file-container > div:last-child {
    margin-bottom: 2rem;
  }
  .file-container > div:first-child {
    margin-top: 0rem;
  }

  .delete-button {
    z-index: 1001;
  }
}

.MuiTooltip-popper {
  white-space: pre;
}
