svg.error-info {
  color: #4b80f066;
  font-size: 1rem;
  margin-left: 0.5rem;
}

.MuiChip-root {
  border-radius: 8px !important;
  background-color: rgba(23, 117, 224, 0.2) !important;
  color: black !important;
  font-weight: 900;
  .MuiChip-label {
    padding-top: 1px;
    border-radius: 8px;
  }
}
