* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
}

#app {
  overflow: hidden;
  height: calc(100vh - var(--topbar-height));
  margin-top: var(--topbar-height)
}

body {
  min-height: 80vh !important;
}