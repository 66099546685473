.textfield-error {
  border: 1px solid red !important;
}

div.MuiTooltip-popper {
  z-index: 2500;

  div.MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
    font-size: 12px;
    max-width: fit-content;
  }
}
