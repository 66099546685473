#filter-select {
  padding-top: 20px;
  position: absolute;
  top: 68px;
  left: 0;
  z-index: 1;
  width: 450px;
  max-height: 300px;
  overflow-y: auto;
  flex-direction: column;
  min-height: 80px;
  background-color: var(--color-light-blue-filter);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  .input-title {
    font-family: Avenir;
    font-size: 12px;
  }

  .param {
    width: 25%;
  }
}
