.modal-data-table {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    max-height: 95vh;
    overflow-y: auto;
    width: 100%;
    min-width: 300px;
    max-width: 90vw;


    .header {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }

    .body {
      margin-top: 10px;
    }
  }
}
