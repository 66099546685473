.visibility {
  .MuiPaper-root {
    padding: 16px 16px 0 16px;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    max-height: 45vh;

    .MuiTypography-root {
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
    }
    .Mui-checked {
      color: #2877f9;
    }
  }
}

button.outlined {
  margin-left: 24px;
}
